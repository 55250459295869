.user {
  max-width: 1200px;
  padding: 40px 20px;
  margin: 0 auto;
  min-height: 100vh;

  @include media-breakpoint-down(sm) {
    padding: 60px 10px 20px 10px;
  }

  table,
  tr,
  th,
  td {
    background: transparent !important;
    border-color: rgba(215, 213, 218, 0.5);
    color: white;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }

  thead,
  th {
    color: $white;
  }

  .btn {
    padding: 8px 20px;
  }

  .btn-white {
    color: $violet;

    &:hover,
    :active,
    :focus {
      color: white;
      border: 2px solid white;
      background: transparent;
    }
  }
}
