// Colors

$white: #fff;
$black: #000;
$grey: #414042;
$pink: #d71b5a;
$mint: #8ee0de;
$violet: #282871;

$violet-gradient: linear-gradient(46deg, #282871 19.57%, #201c52 100%);

$primary: $white;
$secondary: $black;
$headings-color: $white;

$font-family-sans-serif: "Nunito", sans-serif;
$headings-font-family: "Nunito", sans-serif;

$utilities: (
  "custom-margin-top": (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 4rem,
    ),
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      90: 0.9,
      100: 1,
    ),
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 9,
);

$theme-colors: (
  "primary": $white,
  "secondary": $black,
  "white": $white,
  "grey": $grey,
  "pink": $pink,
  "mint": $mint,
  "violet": $violet,
);

// Button
$btn-focus-box-shadow: 0;
$btn-color: $white;
$btn-hover-color: $white;
$btn-border-radius: 40px;
$btn-font-family: $headings-font-family;
$btn-font-size: 18px;
$btn-font-weight: 700;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Form
$input-bg: $white;
$input-btn-font-size-sm: 0.8rem;
$input-border-color: $white;
$input-border-width: 3px;
$input-focus-border-color: $mint;
$input-color: $violet;
$input-focus-box-shadow: none;
$input-padding-x: 1.3rem;
$input-padding-y: 0.4rem;

$form-check-input-bg: $violet;
$form-check-input-width: 1.5em;
$form-check-radio-border-radius: 50%;
$form-check-input-border-radius: 50%;
$form-check-input-border: 2px solid $white;
$form-check-input-focus-border: 2px solid $white;
$form-check-input-checked-color: $violet;
$form-check-input-checked-bg-color: $white;
$form-check-input-checked-border-color: $white;
$form-check-input-focus-box-shadow: "none";

// Cards
$card-border-radius: 15px;

$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap";
