.intro {
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .intro-img {
    position: absolute;
    width: 130%;
    z-index: 2 !important;
    top: -190px;
    left: -70px;

    @include media-breakpoint-down(sm) {
      top: -190px;
      width: 170%;
      left: -130px;
    }
  }

  .paths {
    fill: none;
    stroke-dasharray: 18;
    stroke-width: 18;
    stroke-linejoin: round;
  }

  .mask {
    fill: none;
    stroke: white;
    stroke-width: 10;
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    animation: dash 5s reverse linear infinite;
    animation-delay: 1s;
  }

  /* does not work in IE, need JS to animate there */
  @keyframes dash {
    from {
      stroke-dashoffset: 1200;
    }
    to {
      stroke-dashoffset: -1200;
    }
  }

  .x-eb-1 {
    height: 20px;
    position: absolute;
    top: -25px;
    left: 50px;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      top: -70px;
      left: 40px;
    }
  }

  .x-eb-2 {
    height: 20px;
    position: absolute;
    bottom: 30px;
    left: 10px;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      bottom: 10px;
      left: 20px;
    }
  }

  .x-eb-3 {
    height: 20px;
    position: absolute;
    top: -16px;
    right: 55px;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      top: -35px;
      right: 50px;
    }
  }

  .x-eb-4 {
    height: 20px;
    position: absolute;
    bottom: 30px;
    right: 35px;
    z-index: 10;

    @include media-breakpoint-down(sm) {
      bottom: 30px;
      right: 30px;
    }
  }

  .intro-description {
    max-width: 530px;
    margin: 20px 0;
    position: relative;
    z-index: 8 !important;

    @include media-breakpoint-down(md) {
      padding: 0 12px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
  }
}
