.questions {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @include media-breakpoint-down(sm) {
    justify-content: flex-start;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .explosion-overlay {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }

  .lead {
    p {
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.44px;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
  }
  .x-eb-1 {
    height: 20px;
    position: absolute;
    top: 15%;
    left: 20%;
    z-index: 4;

    @include media-breakpoint-down(lg) {
      top: 8%;
      left: 10%;
    }
  }

  .x-eb-2 {
    height: 20px;
    position: absolute;
    bottom: 24%;
    left: 17%;
    z-index: 4;

    @include media-breakpoint-down(lg) {
      bottom: 15%;
      left: 11%;
    }
  }

  .x-eb-3 {
    height: 20px;
    position: absolute;
    top: 20%;
    right: 18%;
    z-index: 4;

    @include media-breakpoint-down(lg) {
      top: 12%;
      right: 10%;
    }
  }

  .x-eb-4 {
    height: 20px;
    position: absolute;
    bottom: 28%;
    right: 15%;
    z-index: 4;

    @include media-breakpoint-down(lg) {
      bottom: 18%;
      right: 12%;
    }
  }

  .question-pagination {
    position: absolute;
    top: 15%;

    @include media-breakpoint-down(md) {
      top: 10%;
    }
  }

  .question-content {
    margin: 0 auto;
  }

  .image-question-answer-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 480px;
    justify-content: space-between;
    align-items: space-between;
    margin-bottom: 5.5rem;

    @include media-breakpoint-down(md) {
      max-width: 380px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 340px;
      padding: 0;
      margin-bottom: 3.5rem;
    }
  }

  .text-question-answer-wrapper {
    display: flex;
    margin: 0 auto;
    max-width: 405px;
    justify-content: space-between;
    align-items: space-between;

    @include media-breakpoint-down(lg) {
      padding: 0 10px;
    }
  }

  .text-answer-wrapper {
    padding: 0;
    z-index: 5;
  }

  .image-answer-wrapper {
    padding: 10px;
    z-index: 5;
  }

  .btn-question {
    border-radius: 9px;
    background-color: $mint;
    color: $violet;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    transition: 1s;
    text-transform: none;
    font-size: 18px;

    &.image-answer {
      border: 0;
      outline: none;
      width: 210px;
      height: 270px;
      color: $violet;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 auto;

      @include media-breakpoint-down(md) {
        width: 150px;
        height: 250px;
      }

      img {
        width: 140px;

        @include media-breakpoint-down(md) {
          width: 120px;
        }
      }

      .answer-text-small {
        font-size: 14px;
        font-weight: 400;
      }

      &:active,
      &:focus {
        filter: brightness(95%);
        transition: 0.5s;
        box-shadow: inset 0 0 0 5px $white;
      }

      &:hover {
        opacity: 0.7 !important;
      }

      @media (hover: none) {
        &:hover > div {
          opacity: initial;
        }
      }

      > div {
        border: none;
        overflow: hidden;

        img {
          padding: 10px;
        }
      }
    }

    &.text-answer {
      margin: 10px auto;
      text-align: center;
      width: 180px;
      height: 120px;
      font-size: 14px;
      line-height: 20px;
      padding: 20px 30px;

      &:active,
      &:focus {
        //filter: brightness(75%);
        background-color: $white;
      }
      &:hover {
        opacity: 0.7 !important;
      }

      // Disable hover on mobile devices
      @media (hover: none) {
        &:hover {
          opacity: 1 !important;
        }
      }

      @include media-breakpoint-down(md) {
        margin: 5px auto;
      }

      @include media-breakpoint-down(sm) {
        width: 160px;
        height: 120px;
        padding: 20px;
        margin: 8px auto;
      }
    }
  }

  .back-wrapper {
    position: absolute;
    bottom: 8%;
  }

  @media only screen and (max-height: 667px) {
    .back-wrapper {
      bottom: 0;
    }
  }
}
