.optin {
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .x-eb-1 {
    height: 20px;
    position: absolute;
    top: 30%;
    left: 20%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .x-eb-2 {
    height: 20px;
    position: absolute;
    bottom: 30%;
    left: 20%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .x-eb-3 {
    height: 20px;
    position: absolute;
    top: 22%;
    right: 18%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .x-eb-4 {
    height: 20px;
    position: absolute;
    bottom: 35%;
    right: 22%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .optin-form {
    width: 350px;
    margin: 0.8rem auto;

    a {
      text-decoration: none;
      font-weight: 700;
    }
  }

  input {
    font-weight: 700;
    font-size: 13px;
    color: $violet;
    line-height: 25px;
  }

  .form-group {
    margin: 0.7rem 0;
    position: relative;
  }

  .form-control {
    border-radius: 15px;
    background: #f2f2f2;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .form-control::placeholder {
    color: $violet;
    opacity: 1;
  }

  .form-check-label {
    font-size: 9px;
    line-height: 12px;
  }

  .guest-btn {
    font-size: 13px;
    font-weight: 700;
    line-height: 25px;
    margin-top: 45px;
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
      color: $mint;
    }

    &:active,
    &:focus {
      color: $mint;
      outline: none;
      border: none;
    }
  }
}
