.main-product-card {
  max-width: 610px;
  background-color: $white;
  padding: 25px 20px;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: 20px auto;

  h3 {
    font-size: 20px;
    color: $violet;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: left;
  }

  td {
    height: 26px;
    color: $black;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }

  .lead {
    color: $black;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    margin: 0 0 10px;
  }

  .product {
    color: $black;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 5px;
  }

  .check {
    height: 15px;
    margin-right: 10px;
  }

  .btn-result {
    color: $white;
    background-color: $violet;
    font-size: 14px;
    padding: 10px 55px;

    &:hover {
      color: $white;
      background-color: darken($violet, 10);
    }
  }
}
