.card {
  height: 250px;
  margin: 10px auto;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @include media-breakpoint-down(lg) {
    margin-bottom: 30px;
  }

  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    color: $violet;
  }
  p {
    font-family: Nunito;
    font-size: 12px;
    color: $black;
    font-weight: 400;
    line-height: 18px;
    text-align: start;
  }

  .btn-outline-violet {
    color: $violet;
    font-size: 14px;
    padding: 8px 55px;
    border-radius: 10px;

    &:hover,
    :active {
      color: $white;
    }
  }
}
