// icons
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');

// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');

//body
body {
  box-sizing: border-box;
  color: $white;
  font-family: 'Nunito', sans-serif;
  color: $white;
  margin: 0 auto;
  overflow-x: hidden;
  //overflow: hidden;
  padding: 0;
}

html,
#root {
  //min-height: 100vh;
  background-color: $violet;
}

.os-scrollbar {
  right: 3px;
}

.os-theme-dark {
  --os-padding-axis: 40px;
}

.quiz {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  //min-height: 100vh;
  max-width: 100vw;
  font-family: $font-family-sans-serif;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 0.2s ease-in-out;
  background-image: $violet-gradient;

  // Max content width
  .wrapper {
    width: 700px;
    margin: 0 auto;
    min-height: 100vh;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .label {
    position: absolute;
    top: 15px;
    p {
      font-size: 16px;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.32px;
      color: $mint;
    }
  }

  h3 {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
  }

  h2 {
    color: #fff;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.44px;
  }

  p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
  }
}

// Button
.btn {
  font-weight: 700;
}

.btn-main {
  text-transform: uppercase;
  font-size: 18px;
  color: $violet;
  background-color: $mint;
  line-height: normal;
  letter-spacing: -0.36px;
  padding: 10px 28px;
  margin: 10px auto;

  @include media-breakpoint-down(md) {
    padding: 8px 24px;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: none;
    outline: none;
    color: $white;
    background-color: darken($mint, 10);
  }
}

.btn-back {
  border: none;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: auto !important;
  margin-right: auto !important;
  opacity: 0.85;
  outline: none;
  position: relative;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 1.5rem;

  &:active,
  &:focus {
    filter: brightness(75%);
    path {
      fill: $mint;
    }
  }
  &:hover {
    transform: scale(1.05);
    path {
      fill: $mint;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-top: 15px;
    margin-bottom: 1rem;
  }

  .bi-arrow-left {
    font-size: 20px;
    position: relative;
    top: 2px;
  }
}
