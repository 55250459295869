.results {
  flex-direction: column;
  align-content: flex-start;
  padding-top: 50px;

  .related {
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }

  .card-wrap {
    padding: 0px 45px;

    @include media-breakpoint-down(sm) {
      padding: 0px 16px;
    }
  }

  .btn-outline-white {
    color: $white;
    font-size: 14px;
    padding: 10px 38px;

    &:hover,
    :active {
      color: transparentize($white, 0.3);
      border-color: transparentize($white, 0.3);
      background-color: transparent;
    }
  }
}
