.pagination {
  height: 16px;
  width: 136px;
  position: relative;

  .line {
    position: absolute;
    left: 0;
    top: 7.5px;
    height: 0.5px;
    width: 100%;
    background: #85d0ce;
  }

  .bullet {
    border-radius: 4px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    position: absolute;
    top: 4px;
    transition: background-color 0.3s ease;
  }

  .previous {
    background-color: #85d0ce;
    filter: none; /* No shadow for previous bullets */
  }

  .active {
    background-color: #85d0ce;
    box-shadow: 0 0 8.5px #85d0ce;
  }
}
