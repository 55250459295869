.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem auto;

  @include media-breakpoint-down(lg) {
    margin: 1.5rem auto;
    justify-content: flex-start;
  }

  .loading-header {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  .loading-text {
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.22px;
    margin-top: 3rem;
  }
}
